import {Injectable} from '@angular/core';
import {HttpClient, HttpContext, HttpEvent, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, timeout} from 'rxjs/operators';

import {env} from '../../../../environments/env';

import {ApiResponse} from '../../../models/api-response';
import {AuthLevel} from '../../../models/enums/auth-level';
import {ApiUtils} from '../../../utils/api-utils';

@Injectable()
export class ApiService {

  private readonly httpHeaders = new HttpHeaders({
    'content-type': 'application/json',
    'ngsw-bypass': 'true',
    'x-web-version': env.version
  });

  constructor(private http: HttpClient) {
  }

  private static extractData(event: HttpEvent<any>): any {
    if (event.type === HttpEventType.Response) {
      return event.body || {};
    }
  }

  public get<T>(url: string, authLevel: AuthLevel): Observable<ApiResponse<T>> {
    const request = new HttpRequest('GET', url, {
      headers: this.httpHeaders,
      context: new HttpContext().set(ApiUtils.AUTH_LEVEL_CTX, authLevel),
      reportProgress: false,
      responseType: 'json'
    });

    return this.http.request(request).pipe(
      timeout(env.api.timeout),
      map(ApiService.extractData),
      catchError(e => of(ApiUtils.handleAPIError<T>(e)))
    );
  }

  public post<T>(url: string, authLevel: AuthLevel, body: any): Observable<ApiResponse<T>> {
    const request = new HttpRequest('POST', url, body, {
      headers: this.httpHeaders,
      context: new HttpContext().set(ApiUtils.AUTH_LEVEL_CTX, authLevel),
      reportProgress: false,
      responseType: 'json'
    });

    return this.http.request(request).pipe(
      timeout(env.api.timeout),
      map(ApiService.extractData),
      catchError(e => of(ApiUtils.handleAPIError<T>(e)))
    );
  }

  public put<T>(url: string, authLevel: AuthLevel, body: any): Observable<ApiResponse<T>> {
    const request = new HttpRequest('PUT', url, body, {
      headers: this.httpHeaders,
      context: new HttpContext().set(ApiUtils.AUTH_LEVEL_CTX, authLevel),
      reportProgress: false,
      responseType: 'json'
    });

    return this.http.request(request).pipe(
      timeout(env.api.timeout),
      map(ApiService.extractData),
      catchError(e => of(ApiUtils.handleAPIError<T>(e)))
    );
  }

  public delete<T>(url: string, authLevel: AuthLevel): Observable<ApiResponse<T>> {
    const request = new HttpRequest('DELETE', url, {
      headers: this.httpHeaders,
      context: new HttpContext().set(ApiUtils.AUTH_LEVEL_CTX, authLevel),
      reportProgress: false,
      responseType: 'json'
    });

    return this.http.request(request).pipe(
      timeout(env.api.timeout),
      map(ApiService.extractData),
      catchError(e => of(ApiUtils.handleAPIError<T>(e)))
    );
  }

}
