import {Injectable} from '@angular/core';

import {Observable, Subscriber} from 'rxjs';

import {HotToastService, ToastOptions} from '@ngneat/hot-toast';
import {CreateHotToastRef} from '@ngneat/hot-toast/lib/hot-toast.model';

import {ToastOption} from '../../../models/enums/toast-option';
import {ToastData} from '../../../models/toast-data';

import {ToastComponent} from '../components/toast/toast.component';

@Injectable()
export class ToastService {

  constructor(private toast: HotToastService) {
  }

  public observe(message: string, timeout: number, action1?: string, action2?: string): Observable<ToastOption> {
    const data: ToastData = {message, action1, action2, result: ToastOption.NONE};
    const options: ToastOptions<ToastData> = {duration: timeout, data};

    return new Observable<ToastOption>((s: Subscriber<ToastOption>) => {
      const toastRef: CreateHotToastRef<ToastData> = this.toast.show<ToastData>(ToastComponent, options) as CreateHotToastRef<ToastData>;

      toastRef.afterClosed.subscribe(() => {
        s.next(toastRef.data.result);
        s.complete()
      });
    });
  }

  public show(message: string, timeout: number, action1?: string, action2?: string): void {
    const data: ToastData = {message, action1, action2, result: ToastOption.NONE};
    const options: ToastOptions<ToastData> = {duration: timeout, data};

    this.toast.show<ToastData>(ToastComponent, options);
  }
}
