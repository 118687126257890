import {env} from '../../environments/env';

export class ApiRoutes {

  private static readonly API_URL: string = `${env.api.url}:${env.api.port}`;

  public static loginUrl(): string {
    return `${ApiRoutes.API_URL}/v2/auth/login`;
  }

  public static refreshUrl(): string {
    return `${ApiRoutes.API_URL}/v2/auth/refresh`;
  }

  public static changePassword(): string {
    return `${ApiRoutes.API_URL}/v2/auth/change`;
  }

  public static forgotUrl(email: string): string {
    return `${ApiRoutes.API_URL}/v2/auth/restore/request/${email}`;
  }

  public static validateCodeUrl(code: string): string {
    return `${ApiRoutes.API_URL}/v2/auth/restore/validate/${code}`;
  }

  public static restorePasswordUrl(): string {
    return `${ApiRoutes.API_URL}/v2/auth/restore/change`;
  }

  public static sessionUrl(): string {
    return `${ApiRoutes.API_URL}/v2/auth/session`;
  }

  public static googleUrl(operation: string): string {
    return `${ApiRoutes.API_URL}/v2/providers/google/${operation}`;
  }

  public static facebookUrl(operation: string): string {
    return `${ApiRoutes.API_URL}/v2/providers/facebook/${operation}`;
  }

  public static usersUrl(extras = ''): string {
    return `${ApiRoutes.API_URL}/v2/users${extras !== '' ? '/' + extras : ''}`;
  }

  public static statsUrl(id = ''): string {
    return `${ApiRoutes.API_URL}/v2/stats${id !== '' ? '/' + id : ''}`;
  }

  public static profileUrl(extras = ''): string {
    return `${ApiRoutes.API_URL}/v2/profile${extras !== '' ? '/' + extras : ''}`;
  }

  public static eventsUrl(extras = ''): string {
    return `${ApiRoutes.API_URL}/v2/events${extras !== '' ? '/' + extras : ''}`;
  }

  public static inscriptionsUrl(id = ''): string {
    return `${ApiRoutes.API_URL}/v2/inscriptions${id !== '' ? '/' + id : ''}`;
  }

  public static historyUrl(id = ''): string {
    return `${ApiRoutes.API_URL}/v2/history${id !== '' ? '/' + id : ''}`;
  }

  public static notificationsUrl(extras = ''): string {
    return `${ApiRoutes.API_URL}/v2/notifications${extras !== '' ? '/' + extras : ''}`;
  }

  public static devicesUrl(id = ''): string {
    return `${ApiRoutes.API_URL}/v2/devices${id !== '' ? '/' + id : ''}`;
  }

  public static penaltiesUrl(id = ''): string {
    return `${ApiRoutes.API_URL}/v2/penalties${id !== '' ? '/' + id : ''}`;
  }

  public static announcementsUrl(id = ''): string {
    return `${ApiRoutes.API_URL}/v2/announcements${id !== '' ? '/' + id : ''}`;
  }

}
