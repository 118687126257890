import {Injectable} from '@angular/core';

import {StoreKeys} from '../../../models/enums/store-keys';
import {Auth} from '../../../models/auth';
import {General} from '../../../models/general';
import {Installation} from '../../../models/installation';
import {NextNotification} from '../../../models/next-notification';
import {Version} from '../../../models/version';
import {AppStore} from '../../../utils/app-store';
import {AppUtils} from '../../../utils/app-utils';

@Injectable()
export class StoreService {

  constructor() {
    AppStore.loadStore();
  }

  public getAuth(): Auth | undefined {
    return AppStore.get<Auth>(StoreKeys.AUTH);
  }

  public setAuth(auth: Auth): void {
    AppStore.set<Auth>(StoreKeys.AUTH, auth);
  }

  public deleteAuth(): void {
    AppStore.delete(StoreKeys.AUTH);
  }

  public getNextNotification(): NextNotification | undefined {
    return AppStore.get<NextNotification>(StoreKeys.NOTIFICATION_TIME);
  }

  public setNextNotification(nextNotification: NextNotification): void {
    AppStore.set<NextNotification>(StoreKeys.NOTIFICATION_TIME, nextNotification);
  }

  public getVersion(): Version | undefined {
    return AppStore.get<Version>(StoreKeys.VERSION);
  }

  public setVersion(version: Version): void {
    AppStore.set<Version>(StoreKeys.VERSION, version);
  }

  public getInstallationId(): Installation {
    const installation: Installation | undefined = AppStore.get<Installation>(StoreKeys.INSTALLATION);

    if (installation) {
      return {id: installation.id};
    }

    const uuid: string = AppUtils.generateUUID();
    const newInstallation: Installation = {id: uuid};
    this.setInstallationId(newInstallation);

    return newInstallation;
  }

  public setInstallationId(installation: Installation): void {
    AppStore.set<Installation>(StoreKeys.INSTALLATION, installation);
  }

  public getGeneral(): General {
    return AppStore.get<General>(StoreKeys.GENERAL) || {search: '', readAnnouncement: false, openAnnouncement: false};
  }

  public setGeneral(general: General): void {
    AppStore.set<General>(StoreKeys.GENERAL, general);
  }

}
