import {Component, Inject, Optional} from '@angular/core';

import {HotToastRef} from '@ngneat/hot-toast';

import {ToastData} from '../../../../models/toast-data';
import {ToastOption} from "../../../../models/enums/toast-option";

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {

  public data: ToastData;

  constructor(@Optional() @Inject(HotToastRef) public toastRef: HotToastRef<ToastData>) {
    this.data = this.toastRef.data;
  }

  public onClick(action: number): void {
    switch (action) {
      case 1:
        this.toastRef.data.result = ToastOption.OPTION_1;
        break;
      case 2:
        this.toastRef.data.result = ToastOption.OPTION_2;
        break;
      default:
        this.toastRef.data.result = ToastOption.NONE;
    }

    this.toastRef.close({dismissedByAction: true});
  }

}
