import {DateTime} from 'luxon';

import {EventUIDTO} from '../models/dto/event-ui-dto';
import {StatsDTO} from '../models/dto/stats-dto';
import {EventInscriptionDTO} from '../models/dto/event-inscription-dto';
import {Hour} from '../models/hour';
import {NextNotification} from '../models/next-notification';

declare const window: any;

export class AppUtils {

  public static readonly WEEK_DAYS: string[] = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
  public static readonly MONTHS: string[] = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
  public static readonly TIME_TO_NOTIFY_PUSH: number = 604800; // 7 DAYS

  private constructor() {
  }

  public static baToStr(buffer: ArrayBuffer): string {
    const bufferArray: Uint8Array = new Uint8Array(buffer);

    // @ts-ignore
    return window.btoa(String.fromCharCode.apply(null, bufferArray))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }

  public static canShowNotificationPermission(nextNotification?: NextNotification): number {
    const value: number = nextNotification?.ti || 0;

    if (value === 0) {
      return -1;
    }

    const now: number = Math.round(new Date().getTime() / 1000.0);
    const difference: number = now - value;

    if (difference > this.TIME_TO_NOTIFY_PUSH) {
      return -1;
    } else {
      return value + this.TIME_TO_NOTIFY_PUSH;
    }
  }

  public static getAllHours(): Array<Hour> {
    const hours: Array<Hour> = [];
    let currentHour = 6;

    while (currentHour < 24) {
      const currentHourS = ('0' + currentHour).slice(-2);
      const hour24 = currentHourS + ':00';

      hours.push({
        value: hour24,
        label: AppUtils.formatFrom24to12Hours(hour24, false)
      });

      currentHour = currentHour + 1;
    }

    return hours;
  }

  public static isEventCurrentlyExecuting(startDate: string, finishDate: string): boolean {
    const startDateL = DateTime.fromISO(startDate).toLocal();
    const finishDateL = DateTime.fromISO(finishDate).toLocal();
    const currentDateL = DateTime.local();

    return startDateL < currentDateL && finishDateL > currentDateL;
  }

  private static formatFrom24to12Hours(time: string, ignoreZero = true): string {
    const split: Array<string> = time.split(':');
    const hours: number = +split[0];
    const minutes: number = +split[1];

    const modifier = +hours < 12 ? ' AM' : ' PM';
    const hour = +hours % 12 || 12;
    const minute = ignoreZero && minutes === 0 ? '' : `:${minutes}`;

    return hour + minute + modifier;
  }

  public static getUrlParameter(query: string, name: string): string | undefined {
    if (query === '') {
      return undefined;
    }

    name = name.replace(/\[/, '\\[').replace(/]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results: RegExpExecArray | null = regex.exec(query);

    return results === null ? undefined : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  public static doEventAnalysis(evt: EventUIDTO): EventUIDTO {
    let inExecution: boolean = AppUtils.isEventCurrentlyExecuting(evt.startDate, evt.finishDate);
    let inPast: boolean = false;
    let pending: boolean = false;
    let showNextStartDate: boolean | undefined = evt.showNextStartDate;
    let active: boolean = evt.active;

    if (!evt.active) {
      inExecution = false;
      return {...evt, inExecution, inPast, pending};
    }

    if (evt.active && evt.showed) {
      const startDate: DateTime = DateTime.fromISO(evt.startDate).toLocal();

      if (startDate <= DateTime.local()) {
        if (!evt.inExecution) {
          inPast = true;
        }
      }
    } else {
      if (evt.nextActivationDate) {
        const nextActivationDate = DateTime.fromISO(evt.nextActivationDate).toLocal();

        if (nextActivationDate > DateTime.local()) {
          if (evt.repeat) {
            const startDate = DateTime.fromISO(evt.startDate).toLocal();
            showNextStartDate = startDate <= DateTime.local();
          }
          pending = true;
        } else {
          active = false;
        }
      }
    }

    return {...evt, inExecution, inPast, pending, active, showNextStartDate};
  }

  public static getSecondsUntilUpdate(seconds: number): number {
    const min = 60;
    const hr = min * 60;

    if (seconds < min) {          // Menos de 1m, cada 2s
      return 2;
    } else if (seconds < hr) {    // Menos de 1h, cada 30s
      return 30;
    } else {                      // Mas de 1h, cada 1h
      return 3600;
    }

  }

  public static get12Hour(date: Date): string {
    const hour = date.getHours();
    const minutes = date.getMinutes();

    if (hour < 12) {
      return `${String(hour).padStart(2, '0')}:${String(minutes).padStart(2, '0')} a. m.`;
    } else if (hour === 12) {
      return `12:${String(minutes).padStart(2, '0')} p. m.`;
    } else {
      return `${String(hour - 12).padStart(2, '0')}:${String(minutes).padStart(2, '0')} p. m.`;
    }
  }

  public static getDayName(date: Date): string {
    return AppUtils.capitalizeFirst(AppUtils.WEEK_DAYS[date.getDay()]);
  }

  public static getMonthDay(date: Date): string {
    return `${String(date.getDate()).padStart(2, '0')} ${AppUtils.MONTHS[date.getMonth()]} ${date.getFullYear()}`;
  }

  public static isToday(d1: Date): boolean {
    const d2 = new Date();

    return d1.getFullYear() === d2.getFullYear()
      && d1.getMonth() === d2.getMonth()
      && d1.getDate() === d2.getDate();
  }

  public static isYesterday(d1: Date): boolean {
    d1 = new Date(d1.getTime());
    const d2 = new Date();

    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);

    return d2.getTime() - d1.getTime() >= 86400000 && d2.getTime() - d1.getTime() < 172800000;
  }

  public static isPastWeek(d1: Date): boolean {
    d1 = new Date(d1.getTime());
    const d2 = new Date();

    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);

    return d2.getTime() - d1.getTime() >= 172800000 && d2.getTime() - d1.getTime() < 604800000;
  }

  public static calculatePercents(stats: StatsDTO): StatsDTO {
    let assistsPercent: number = (stats.assists * 100) / stats.events;
    let waitsPercent: number = (stats.waits * 100) / stats.events;
    let cancellationsPercent: number = (stats.cancellations * 100) / stats.events;
    let penaltiesPercent: number = (stats.penalties * 100) / stats.events;

    if (isNaN(stats.assistsPercent)) {
      assistsPercent = Number(0);
    }

    if (isNaN(stats.waitsPercent)) {
      waitsPercent = Number(0);
    }

    if (isNaN(stats.cancellationsPercent)) {
      cancellationsPercent = Number(0);
    }

    if (isNaN(stats.penaltiesPercent)) {
      penaltiesPercent = Number(0);
    }

    return {...stats, assistsPercent, waitsPercent, cancellationsPercent, penaltiesPercent};
  }

  public static capitalizeFirst(value: string): string {

    if (value === null || value === undefined || value === '') {
      return '';
    }

    const splitStr = value.trim().toLowerCase().split(' ');

    for (let i = 0; i < splitStr.length; i++) {
      if (i === 0) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1).toLowerCase();
      } else {
        splitStr[i] = splitStr[i].toLowerCase();
      }
    }

    return splitStr.join(' ');
  }

  public static generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public static isPublicPath(path: string = ''): boolean {
    return path.indexOf('forgot') > 0 || path.indexOf('register') > 0 || path.indexOf('restore') > 0 || path.indexOf('info') > 0 || path.indexOf('faqs') > 0 || path.indexOf('privacy') > 0 || path.indexOf('terms') > 0;
  }

  public static pick(object: Record<string, any>, keys: string[]): Record<string, any> {
    return ({...keys.reduce((res: {}, key: string) => ({...res, [key]: object[key]}), {})});
  }

  public static deleteLoader(): void {
    setTimeout(() => document.getElementById('init-preloader')?.remove(), 0);
  }

  public static sortByDate(a: EventInscriptionDTO, b: EventInscriptionDTO): number {
    if (a.canceledAt === undefined) {
      return 0;
    }

    if (b.canceledAt === undefined) {
      return 0;
    }

    const aDate: DateTime = DateTime.fromISO(a.canceledAt);
    const bDate: DateTime = DateTime.fromISO(b.canceledAt);

    return aDate < bDate ? 1 : -1;
  }

}
