import {Injectable} from '@angular/core';
import {ComponentType} from '@angular/cdk/overlay';
import {MatDialog} from '@angular/material/dialog';

@Injectable()
export class ModalService {

  constructor(private dialog: MatDialog) {
  }

  public open(component: ComponentType<any>, data: any = {}): void {
    const config = (component as any).CONFIG;
    config.data = data;

    this.dialog.open(component, config);
  }

}
