export enum BusEvents {
  APP_ALL = '**',

  APP_PROFILE = 'APP:PROFILE',
  APP_WEB_PUSH = 'APP:WEB_PUSH',
  APP_GOOGLE = 'APP:GOOGLE',
  APP_SESSION = 'APP:SESSION',
  APP_SESSION_EXPIRED = 'APP:SESSION_EXPIRED',
  APP_CONFIG = 'APP:CONFIG',

  RT_API = 'RT:API',
  RT_ANNOUNCEMENTS = 'RT:ANNOUNCEMENTS',
  RT_ANNOUNCEMENTS_READERS = 'RT:ANNOUNCEMENTS_READERS',
  RT_EMAILS = 'RT:EMAILS',
  RT_EVENTS = 'RT:EVENTS',
  RT_HISTORY = 'RT:HISTORY',
  RT_PEOPLE = 'RT:PEOPLE',
  RT_PUSH_DEVICES = 'RT:PUSH_DEVICES',
  RT_PUSH_MESSAGES = 'RT:PUSH_MESSAGES',
  RT_USERS = 'RT:USERS',
  RT_WEB = 'RT:WEB'
}
