export class ApiErrorMessages {

  private static readonly ERRORS: Record<string, string> = {
    '0': `¡Ups! No hemos podido obtener una respuesta después de un tiempo. Revisa tu conexión a internet e intenta nuevamente.`,
    '1': `¡Ups! Algo pasó. Intenta otra vez, recarga la app o revisa tu conexión a Internet.`,
    '2': 'Uno o mas parámetros de la URL son inválidos',
    '3': 'El recurso solicitado no fue encontrado o no existe',
    '4': 'El cuerpo de la solicitud es inválido',
    '5': 'El campo «{{field}}» ya se encuentra registrado en el sistema',
    '6': 'El campo «{{field}}» ya se encuentra registrado en el sistema',
    '7': 'El usuario no existe en la plataforma',
    '8': 'El usuario no está activo en el sistema. Por favor, comuníquese con el administrador de la plataforma',
    '9': 'El usuario y/o la clave son incorrectos',
    '10': 'Usuario sin un rol válido asociado',
    '11': 'El código de actualización asociado a la autenticación no fue encontrado',
    '12': 'El código de actualización asociado a la autenticación ya expiró',
    '13': 'El código de acceso asociado a la autenticación no fue encontrado',
    '14': 'El código de restablecimiento de la clave no fue encontrado o ya fue usado',
    '15': 'El código de restablecimiento de la clave ha expirado',
    '16': 'Su sesión ha caducado. Por favor inicie sesión nuevamente',
    '17': 'El rol de usuario que posee no le permite realizar la acción solicitada',
    '18': 'Su sesión ha caducado. Por favor inicie sesión nuevamente',
    '19': 'La solicitud que está realizando no coincide con la sesión actualmente abierta',
    '20': 'El token de sesión de Google es inválido o ha caducado',
    '21': 'Esta cuenta de Google está asociada actualmente a otro usuario',
    '22': 'Esta cuenta de Facebook está asociada actualmente a otro usuario',
    '23': 'Error en la verificación de su cuenta de Google',
    '24': 'Hubo un error eliminando la vinculación con Google. Intenta nuevamente',
    '25': 'El token de sesión de Facebook es inválido o ha caducado',
    '26': 'Error en la verificación de su cuenta de Facebook',
    '27': 'La fecha de inicio del evento es más antigua que la fecha actual',
    '28': 'El evento se encuentra actualmente en estado INACTIVO',
    '29': 'La fecha de fin del evento es antes de la fecha de inicio',
    '30': 'El evento se encuentra actualmente PUBLICADO en la aplicación',
    '31': 'El evento no se encuentra configurado para sortear equipos',
    '32': 'Los equipos del evento ya han sido previamente sorteados',
    '33': 'El evento se encuentra actualmente OCULTO en la aplicación',
    '34': 'Hubo un error en el procesamiento del archivo',
    '35': 'El evento debe tener la repetición semanal activada para poder ser cancelado anticipadamente',
    '36': 'El evento no cuenta con las fechas necesarias para ser publicado',
    '37': 'El evento no se encuentra actualmente publicado y/o activo',
    '38': 'Hay problemas en el servidor. Intenta nuevamente',
    '39': 'Hubo un error eliminando la vinculación con Facebook. Intenta nuevamente',
    '40': 'ID de la app para Safari no es válido',
    '41': 'Versión para Safari no es válida',
    '42': 'Consulta al servicio externo de User-Agent falló',
    '43': 'No te puedes inscribir a este evento: no tiene cobro anticipado activado',
    '44': 'No te puedes inscribir a este evento: tiene cobro anticipado activado',
    '45': 'No se pudo validar tu pago correctamente. Revisa el comprobante de pago e intenta nuevamente'
  };

  public static getErrorText(code: number): string {
    const errorCode = String(code);
    return ApiErrorMessages.ERRORS[errorCode] || ApiErrorMessages.ERRORS['1'];
  }

  public static getServerFields(): string[] {
    return [
      'users_email_unique',
      'name',
      'email',
      'assigned_to',
      'property_field_id_value',
      'users.email'
    ];
  }

  public static getFieldNames(): string[] {
    return [
      'Correo Electrónico',
      'Nombre',
      'Correo Electrónico',
      'Asignado',
      'Opción',
      'Correo Electrónico'
    ];
  }

}
