<div class="app-modal">

  <h4 mat-dialog-title class="emergency-contact-title">
    <span [innerHTML]="alert.title"></span>
  </h4>

  <mat-dialog-content class="mat-typography mb-2">
    <span [innerHTML]="alert.body"></span>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button color="primary" [mat-dialog-close]="true" (click)="cancelButton()" *ngIf="alert.type === type.WARNING || alert.type === type.ERROR" class="text-uppercase" [innerHTML]="alert.cancelText"></button>
    <button mat-button color="primary" [mat-dialog-close]="true" (click)="okButton()" cdkfocusinitial class="text-uppercase" [innerHTML]="alert.okText"></button>
  </mat-dialog-actions>

</div>
