import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';

import {AlertType} from '../../../../models/enums/alert-type';
import {AlertDTO} from '../../../../models/dto/alert-dto';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent {

  static CONFIG: MatDialogConfig = {
    closeOnNavigation: false,
    disableClose: true,
    hasBackdrop: true,
    width: '90vw',
    maxWidth: 450
  };

  public type: typeof AlertType = AlertType;
  public alert: AlertDTO;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
    this.alert = this.data.alert;
  }

  public okButton(): void {
    if (this.alert.okCallback !== null && this.alert.okCallback !== undefined && typeof this.alert.okCallback === 'function') {
      this.alert.okCallback();
    }
  }

  public cancelButton(): void {
    if (this.alert.cancelCallback !== null && this.alert.cancelCallback !== undefined && typeof this.alert.cancelCallback === 'function') {
      this.alert.cancelCallback();
    }
  }

}
